import {
  eMobileMenu,
  eMenu,
  debounce,
  screenCategory
  }
  from './library';

  import AOS from 'aos';
  import Glide from '@glidejs/glide'; 
  

  //Global Project Variables and elements
  const MAIN_MENU = document.querySelector('#main-navigation .menu'); //main menu
  const Burger_Button = document.getElementById('burger-icon'); // burger icon
  const Body = document.querySelector('body'); // burger icon
  
  //This object is used store settings for mobile menu
  const MOBILE_MENU_SETTINGS = {
    element: Burger_Button,
    body: Body,
    menu: MAIN_MENU,
    direction: 'right', //options are left and right
  };
  //The variance in when the function on the scroll listener will fire
  const SCROLL_DEBOUNCE = 5;
  //The variance in when the function on the resize listener will fire
  const RESIZE_DEBOUNCE = 5;

  //Create mobile menu
  eMobileMenu(MOBILE_MENU_SETTINGS);

  //create main navigation menu
  eMenu(MAIN_MENU);

  // //on scroll event listener comment out if you don't need it.
  // window.addEventListener('scroll', debounce(() => {
  //   //example of calling animation function
  //   //animate(element,null, 100);
  //   }
  // , SCROLL_DEBOUNCE));

  window.addEventListener('resize', debounce(() => {
    "use strict";
    //check if window is at 1000 pixels or larger
    if(screenCategory() === 'desktop') {
      //check if mobile menu is still open
      if(MAIN_MENU.classList.contains('open')) {
        //check the direction of the animation
        let close = MOBILE_MENU_SETTINGS.direction === 'left' ? 'navFadeOutLeft' : 'navFadeOutRight';
        //create click event to close mobile menu
        MOBILE_MENU_SETTINGS.element.click();
        //remove the closing animation
        MOBILE_MENU_SETTINGS.menu.classList.remove(close);
      }
    }
  },RESIZE_DEBOUNCE));


// -----------------
// Animate on Scroll

AOS.init({
  once: true,
  duration: 900,
  disable: 'phone',
});

// ------
// To Top

const toTopTrigger = document.querySelector('.to-top');

toTopTrigger.addEventListener('click', () => {
  setTimeout(function () {
    removeHash();
  }, 1000);
});

window.addEventListener('scroll', function(e) {
  setTimeout(function () {
    if( window.pageYOffset > (document.documentElement.scrollHeight / 3) ) {
      toTopTrigger.classList.add('fixed');
      toTopTrigger.classList.remove('hidden');
    } else {
      toTopTrigger.classList.add('hidden');
      toTopTrigger.classList.remove('fixed');
    }
  }, 1000);
});

function removeHash() { 
  var scrollV, scrollH, loc = window.location;
  if ("pushState" in history)
      history.pushState("", document.title, loc.pathname + loc.search);
  else {
      // Prevent scrolling by storing the page's current scroll offset
      scrollV = document.body.scrollTop;
      scrollH = document.body.scrollLeft;

      loc.hash = "";

      // Restore the scroll offset, should be flicker free
      document.body.scrollTop = scrollV;
      document.body.scrollLeft = scrollH;
  }
}

// --------------
// EVENT TRACKING

const downloads = document.querySelectorAll('.lf-downloads a');

downloads.forEach(function(download){
  download.addEventListener('click', () => {
    let eventData = download.dataset.eventTitle || '';

    ga('send', {
      hitType: 'event',
      eventCategory: 'Downloads',
      eventAction: 'download',
      eventLabel: eventData
    });

  });
});



// ---------
// ACCORDION

const accordionItems = document.querySelectorAll('.lf-accordion-item');

for (const accordion of accordionItems){
  const accordionTitle = accordion.querySelector('.accordion-label');
  const accordionText = accordion.querySelector('.accordion-text');

  // Hide accordion text
  accordionText.style.display = 'none';
  
  // Display on toggle
  accordionTitle.addEventListener('click', function(){
    accordion.classList.toggle('open');
  });
}

// ---------
// GALLERIES

const largeImage = document.querySelector('.large-image img');
const thumbnails = document.querySelectorAll('.glide__slide a');

if(largeImage){

  for(const thumbnail of thumbnails){
    thumbnail.addEventListener('click', function(){
      const src = thumbnail.dataset.large;
      largeImage.src = src;
    });
  }
  new Glide('.thumbnails', {
    type: 'slider',
    startAt: 0,
    perView: 5,
    peek: 10,
    breakpoints: {
  
      680: {
        perView: 3
      }
    }
  }).mount();
}

// ---------
// HOME NEWS

// Homepage Slider

const hasSlides = document.querySelector('.home-slides');

if(hasSlides){
  new Glide('.home-slides', {
    type: 'carousel',
    startAt: 0,
    perView: 3,
    gap: 130,
    peek: -150,
    focusAt: 'center',
    swipeThreshold: 50,
    breakpoints: {

      3000: {
        perView: 4,
        peek: -125,
        gap: 100
      },

      1850: {
        perView: 3,
        peek: -150,
        gap: 110
      },
      1000: {
        perView: 2,
        peek: 50,
        gap: 25
      },
      600: {
        perView: 1,
        peek: 50,
        gap: 25
      }
    }
  }).mount();
}

// --------------------
// HOME HIGHLIGHT CARDS

const highlight = document.querySelector('.card-2');

if(highlight){
  highlight.addEventListener('mouseenter', function(){
    //highlight.classList.add('hover');
  });
}

// -------------------
// STAFF + BOARD CARDS

const readMores = document.querySelectorAll('.read-more-toggle');

if(readMores){
  for(const readMore of readMores){
    readMore.addEventListener('click', function(){
      
      const parent = readMore.parentElement;
      
      parent.classList.toggle('read-more');

      if(parent.classList.contains('read-more')){
        readMore.textContent = 'Read Less';
        readMore.classList.add('toggled');
      } else {
        readMore.textContent = 'Read More';
        readMore.classList.remove('toggled');
      }

    });
  }
}

// ---------------
// DOCUMENT CENTER

// const documentWrap = document.querySelector('.document-center');
// const documents = document.querySelectorAll('.document');
// const docSorter = document.querySelector('#document-sorter');

// if(documentWrap){
//   docSorter.addEventListener('change', docSort);
  
//   function docSort(){
//     const sortValue = docSorter.value;
    
//     if(sortValue == 'az'){
//       Array.from(documents).sort((a, b) =>   
//         a.dataset.title.toLowerCase().localeCompare(b.dataset.title.toLowerCase())
//       ).forEach(el => el.parentNode.appendChild(el));
//     } else if(sortValue == 'za'){
//       Array.from(documents).sort((a, b) =>   
//         b.dataset.title.toLowerCase().localeCompare(a.dataset.title.toLowerCase())
//       ).forEach(el => el.parentNode.appendChild(el));
//     } else if (sortValue == 'newest'){
    
//       Array.from(documents).sort((a, b) =>   
//         b.dataset.date.toLowerCase().localeCompare(a.dataset.date.toLowerCase())
//       ).forEach(el => el.parentNode.appendChild(el));
//     } else if (sortValue == 'oldest'){
//       Array.from(documents).sort((a, b) =>   
//         a.dataset.date.toLowerCase().localeCompare(b.dataset.date.toLowerCase())
//       ).forEach(el => el.parentNode.appendChild(el));
//     }
//   }
// }

// ---------------
// INCENTIVES

const incentiveFilter = document.querySelectorAll('.incentives-filter a');

for(const filter of incentiveFilter){
  filter.addEventListener('click', function(){
    const siblings = filter.parentNode.children;
    for(const sibling of siblings){
      sibling.classList.remove('active');
    }
    filter.classList.add('active');

    const incentiveLevel = filter.dataset.level;
    incentiveSort(incentiveLevel);
  });
}

function incentiveSort(level){
  const incentiveWrapper = document.querySelectorAll('.incentive-wrap');
  
  for(const item of incentiveWrapper){
    if(level == 'all'){
      item.classList.add('active');
    } else if(item.classList.contains(level)) {
      item.classList.add('active');
    } else {
      item.classList.remove('active');
    }
  }
}




